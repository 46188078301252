<template>
  <!--
    The view for the MessageList-component.
    Used to display the messages within a template.
  -->
  <Portlet
    :sub-title="subTitle"
    :title="`${ $t('menu.templates') }:`"
    icon="clone"
  >
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template
        v-if="anyLanguagesLeft"
        slot="buttons"
      >
        <button
          class="btn btn-primary float-right mt-2"
          @click="showAddMessage"
        >
          <font-awesome-icon
            class="mr-2"
            icon="plus"
          />
          <span>{{ $t('add') }}</span>
        </button>
        <div class="clearfix" />
      </template>
      <MessageList
        ref="messageList"
        :template-id="templateId"
        @checkLanguages="checkLanguages()"
        @reloadAuditLogs="reloadAuditLogs"
      />
      <DatabaseAuditLogList
        ref="databaseAuditLogList"
        :template-id-for-message="templateId"
        @reload="reloadMessagesList"
      /> 
    </template>
  </Portlet>
</template>

<script>
export default {
  name: "MessageOverview",
  components: {
    MessageList: () => import('@/components/SmsManagement/MessageList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  props: {
    templateId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      anyLanguagesLeft: false,
      loading: true,
      template: {
        name: null,
        installationType: null
      }
    }
  },
  metaInfo () {
    return {
      title: this.$t('smsManagement')
    }
  },
  computed: {
    subTitle: function () {
      let result = this.$t('messageOverviewView.messageOverview');
      if (!this.template) {
        return result;
      }
      if (!this.template.name) {
        return result;
      }
      if (!this.template.installationType) {
        return result;
      }
      return `${ this.template.name } (${ this.template.installationType })`;
    }
  },
  created () {
    this.checkLanguages();
    this.getTemplate();
  },
  methods: {
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    reloadMessagesList () {
      this.$refs.messageList.getData();
    },
    getTemplate () {
      this.axios.get(`/SmsManagement/GetTemplate?templateId=${ this.templateId }`)
        .then((response) => {
          this.template = response.data;
        });
    },
    checkLanguages () {
      this.axios.get(`/SmsManagement/AnyLanguagesLeft?templateId=${ this.templateId }`)
        .then((response) => {
          this.anyLanguagesLeft = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showAddMessage () {
      this.$refs.messageList.showAddMessage();
    }
  },
}
</script>
